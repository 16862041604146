<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Master Pengguna</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Kode / nama anggota"
              single-line
              hide-details
            ></v-text-field>
            <v-btn outlined text @click="searching()"> Cari </v-btn>

            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()"> Tambah </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-if="editedIndex == -1"
                          v-model="editedItem.username"
                          label="Username *"
                        ></v-text-field>
                        <v-text-field v-else readonly v-model="editedItem.username" label="Username *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.name" label="Nama *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.email" label="Email *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label for="">Role *</label>
                        <v-select
                          v-model="editedItem.role"
                          :items="roles"
                          item-text="text"
                          item-value="value"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedIndex == -1">
                        <v-text-field value="Sesuai dengan password default" readonly label="Password *"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.password_baru" label="Password"></v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" v-if="editedIndex != -1" @click="reset_password"> Reset Password </v-btn>
                  <v-btn color="primary" v-if="editedIndex != -1" @click="reset_pin"> Reset Pin </v-btn>
                  <v-btn color="primary" @click="save"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.aksi="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Username', value: 'username', sortable: false },
      { text: 'Nama', value: 'name', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Role', value: 'role', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    editedIndex: -1,
    password_baru: '',
    editedItem: {
      id: '',
      username: '',
      name: '',
      email: '',
      role: '',
      password: '',
      foto_profil: '',
      pin: '',
      id_anggota: '',
    },
    defaultItem: {
      id: '',
      username: '',
      name: '',
      email: '',
      role: '',
      password: '',
      foto_profil: '',
      pin: '',
      id_anggota: '',
    },
    // roles: ['ADMIN', 'KETUA', 'WAKIL', 'SEKRETARIS', 'BENDAHARA', 'ANGGOTA', 'HRD'],
    roles: ['ADMIN', 'HRD', 'PENGAWAS'],
    search: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Pengguna' : 'Edit Pengguna'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    // initialize() {
    // },
    getDataFromApi() {
      this.loading = true
      const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.search)
      form.append('page', page)
      form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/User/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    openDialog() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('username', this.editedItem.username)
        form.append('name', this.editedItem.name)
        form.append('email', this.editedItem.email)
        form.append('role', this.editedItem.role)
        axios
          .post(`${apiRoot}/api/User/update`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('username', this.editedItem.username)
        form.append('name', this.editedItem.name)
        form.append('email', this.editedItem.email)
        form.append('role', this.editedItem.role)
        axios
          .post(`${apiRoot}/api/User/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    reset_password() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        const conf = confirm('Apakah yakin akan reset password?')
        if (conf) {
          this.$store.dispatch('loading', true)
          const headers = {
            Authorization: this.token,
          }
          const form = new FormData()
          form.append('id', this.editedItem.id)
          axios
            .post(`${apiRoot}/api/User/resetPassword`, form, { headers })
            .then(response => {
              if (response.data.code === 401) {
                this.$store.dispatch('logout', 'Session berakhir!')
              } else if (response.data.code === 200) {
                this.search = ''
                this.options.page = 1
                this.getDataFromApi()
                this.close()
              } else {
                alert(response.data.message)
              }
              this.$store.dispatch('loading', false)
            })
            .catch(error => {
              alert(error)
              this.$store.dispatch('loading', false)
            })
        }
      }
    },
    reset_pin() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        const conf = confirm('Apakah yakin akan reset pin?')
        if (conf) {
          this.$store.dispatch('loading', true)
          const headers = {
            Authorization: this.token,
          }
          const form = new FormData()
          form.append('id', this.editedItem.id)
          axios
            .post(`${apiRoot}/api/User/resetPin`, form, { headers })
            .then(response => {
              if (response.data.code === 401) {
                this.$store.dispatch('logout', 'Session berakhir!')
              } else if (response.data.code === 200) {
                this.search = ''
                this.options.page = 1
                this.getDataFromApi()
                this.close()
              } else {
                alert(response.data.message)
              }
              this.$store.dispatch('loading', false)
            })
            .catch(error => {
              alert(error)
              this.$store.dispatch('loading', false)
            })
        }
      }
    },

    jenisUpahChange() {},
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
